function tooltip_init() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  });
}

function custom_file_input_init() {
  $('.custom-file-input').on('change', function () {
      let fileName = $(this).val().split('\\').pop();
      $(this).siblings('.custom-file-label').addClass("selected").html(fileName);
  });
}

// clickable nodes
function clickable_nodes() {
  $("*[data-link]").click(function (event) {
    // event.stopPropagation();
    if (event.target.tagName.toLowerCase() != "a")
      window.location = $(this).data("link");
  });
}

  // # reloadable tabs selection
  // $(->
  //   hash = document.location.hash
  //   prefix = "tab_"
  //
  //   hash && $('ul.nav a[href="' + hash.replace(prefix,"") + '"]').tab('show')
  //
  //   # Change hash for page-reload
  //   $('ul.nav a').on('shown.bs.tab', (e) ->
  //       document.location.hash = e.target.hash.replace("#", "#" + prefix)
  //   )
  // )

initModules.push(tooltip_init);
initModules.push(custom_file_input_init);
initModules.push(clickable_nodes);
