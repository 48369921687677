function goToDish( id ) {
  var target = "/dishes/" + id;
  if (target != location.pathname)
    location.pathname = target;
}

function showDish_onClickCard() {
  $('.dish').click(function (event) {
    var target = $(event.target);
    if (!(target.closest('a').length)) {
      // The target is a element of dish card EXCEPT A LINK
      goToDish(target.closest('.dish').attr('id'));
    }
  });
}

function addIngredient_toDish() {
  $('.add-ingredient').click(function (event) {
    $('#edit-ingredients-list').append(
      $('<li/>').append(
        [
          $('<input/>', {
            'type': "text",
            'class': "form-control",
            'name': "dish[ingredients][]",
          }),
          $('<a/>', {
            'class': "btn btn-danger cancel-ingredient",
            'href': "#",
            'role': "button",
          }).text('X')
        ]
      )
    );
    cancelIngredient_toDish();
    event.preventDefault(); // Prevent link from following its href
  });
}

function cancelIngredient_toDish() {
  $('.cancel-ingredient').click(function (event) {
    $(event.target).parent('li').remove();
    event.preventDefault(); // Prevent link from following its href
  });
}

initModules.push(showDish_onClickCard);
initModules.push(addIngredient_toDish);
initModules.push(cancelIngredient_toDish);
